









import { Prop } from "vue-property-decorator";
import Component, { mixins } from "vue-class-component";

import { TechnologyMixin } from "@/mixins/technology.mixin";

import { Technology } from "@/interfaces/technology";

@Component
export default class TechnologiesGrid extends mixins(TechnologyMixin) {
  /** Available technologies */
  @Prop() technologies!: Technology[];
}
